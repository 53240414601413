<template>
	<div class="d-flex flex-column overflow-hidden h-100">
		<vue-custom-scrollbar class="app-content-padding flex-grow-1 overflow-hidden h-100">
			<ts-page-title :title="$t('employee.pageTitle')" :breadcrumb="[
				{ text: $t('home'), href: '/' },
				{
					text: $t('employee.pageTitle'),
					href: '/admin/employees'
				},
				{
					text: $t('create'),
					active: true
				}
			]" />
			<div class="demo-spin-article">
				<div class="row">
					<div class="col-md-3 tw-mb-5">
						<profile v-model="model" :validate="errors" class="tw-h-full" />
					</div>
					<div class="col-md-9 tw-mb-5">
						<ts-panel>
							<ts-panel-wrapper>
								<form class="form-horizontal form-horizontal-text-right">
									<details-template ref="detial" v-model="model" :validation="errors" />
									<Tabs v-model="tabValue">
										<TabPane label="DOCUMENTS" name="documents">
											<document-template v-if="tabValue == 'documents'" v-model="model"
												:validation="errors" />
										</TabPane>
										<TabPane label="EDUCATION HISTORY" name="education_history">
											<education-history v-if="tabValue == 'education_history'" v-model="model"
												:validation="errors" />
										</TabPane>
										<TabPane label="EMPLOYMENT HISTORY" name="employment_history">
											<employment-history v-if="tabValue == 'employment_history'" v-model="model"
												:validation="errors" />
										</TabPane>
									</Tabs>
									<payroll-template v-model="model" :validation="errors" />
									<attach-file v-model="model" :validation="errors" />
								</form>
							</ts-panel-wrapper>
						</ts-panel>
					</div>
				</div>
			</div>
			<Spin size="large" fix v-if="loading"> </Spin>
		</vue-custom-scrollbar>
		<div id="footer" class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh" style="background-color: #e9ecef">
			<ts-button @click.prevent="$router.push({ name: 'employee' })" :disabled="waiting_new || waiting"
				class="btn-gray">
				{{ $t("cancel") }}</ts-button>
			<ts-button color="primary" outline :waiting="waiting_new" :disabled="waiting_new || waiting"
				@click.prevent="onSaveAddNew">
				{{ $t("saveAddNew") }}</ts-button>
			<ts-button color="primary" :waiting="waiting" :disabled="waiting_new || waiting" @click.prevent="onSave">
				{{ $t("save") }}</ts-button>
		</div>
	</div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import Profile from "./components/profile.vue";
import DocumentTemplate from "./components/document.vue";
import EducationHistory from "./components/education-history.vue";
import EmploymentHistory from "./components/employment-history.vue";
import DetailsTemplate from "./components/detail";
import PayrollTemplate from "./components/payroll";
import AttachFile from "./components/attach-file.vue";
import { mapActions } from "vuex";
import { Errors } from "form-backend-validation";
import moment from "moment";

export default {
	name: "employeeCreate",
	components: {
		Profile,
		DocumentTemplate,
		EducationHistory,
		EmploymentHistory,
		DetailsTemplate,
		AttachFile,
		PayrollTemplate
	},
	data() {
		return {
			loading: false,
			waiting: false,
			waiting_new: false,
			errors: new Errors(),
			tabValue: 'documents',
			model: {
				employee_name_kh: null,
				employee_name_en: null,
				sex: null,
				phone: null,
				card_id: null,
				email: null,
				address: null,
				photo: null,
				position_id: null,
				hire_date: moment().format("DD-MM-YYYY"),
				probation_end_date: moment()
					.add(3, "M")
					.format("DD-MM-YYYY"),
				status_id: 1,
				status_effective_date: null,
				shift_id: null,
				pc_date_id: null,
				payment_type_id: null,
				is_tax_responsed_by_company: false,
				is_spouse_dependant: false,
				num_child_dependant: 0,
				nssf_number: null,
				ssn_no: null,
				ssn_issued_date: null,
				ssn_expiry_date: null,
				ssn_issued_at: null,
				nationality_id: 2,
				date_of_birth: null,
				emp_type_id: 1,
				status_reason_id: null,
				fp_serial_card: null,
				num_of_payroll: 1,
				branch_id: null,
				province_code: null,
				disctrict_code: null,
				commune_code: null,
				village_code: null,
				is_employer: false,
				is_audit: false,
				details: [],
				education_histories: [],
				employment_histories: [],
				attach_file_list: [],
				is_sm: false
			}
		};
	},
	created() {
		AppOptions.appContentFullHeight = true;
		AppOptions.appContentClass = "p-0";
	},
	methods: {
		...mapActions("humanResource/employee", [
			"getPosition",
			"getStatus",
			"getStatusReason",
			"getShift",
			"getPcDate",
			"getPaymentType",
			"getNationality"
		]),
		async fetchResource() {
			this.loading = true;
			await this.getPosition();
			await this.getStatus();
			await this.getStatusReason();
			await this.getShift();
			await this.getPcDate();
			await this.getPaymentType();
			await this.getNationality();
			this.$refs.detial.fetchBranch();
			this.loading = false;
		},
		photoUpload() {
			if (
				this.model.photo instanceof File ||
				this.model.photo instanceof Blob
			) {
				let formData = new FormData();
				formData.append("photo", this.model.photo);
				return this.$store.dispatch(
					"humanResource/employee/photoUpload",
					formData
				);
			}

			return this.model.photo;
		},
		async onSaveAddNew() {
			this.errors = new Errors();
			let photo_path = await this.photoUpload();
			this.waiting_new = true;
			this.$store
				.dispatch(
					"humanResource/employee/store",
					Object.assign({}, this.model, { photo: photo_path })
				)
				.then(response => {
					this.notice({
						type: response.message_id == 0 ? "success" : "warning",
						text: response.message
					});
					this.clearInput();
				})
				.catch(error => {
					this.errors = new Errors(error.errors);
					this.notice({ type: "error", text: error.message });
				})
				.finally(() => {
					this.waiting_new = false;
				});
		},
		async onSave() {
			this.errors = new Errors();
			let photo_path = await this.photoUpload();
			this.waiting = true;
			this.$store
				.dispatch(
					"humanResource/employee/store",
					Object.assign({}, this.model, { photo: photo_path })
				)
				.then(response => {
					this.notice({
						type: response.message_id == 0 ? "success" : "warning",
						text: response.message
					});
					this.clearInput();
					this.$router.push({ name: "employee" });
				})
				.catch(error => {
					this.errors = new Errors(error.errors);
					this.notice({ type: "error", text: error.message });
				})
				.finally(() => {
					this.waiting = false;
				});
		},
		clearInput() {
			this.model.employee_name_kh = null
			this.model.employee_name_en = null
			this.model.sex = null
			this.model.phone = null
			this.model.card_id = null
			this.model.email = null
			this.model.address = null
			this.model.photo = null
			this.model.position_id = null
			this.model.hire_date = moment().format("DD-MM-YYYY")
			this.model.probation_end_date = moment()
				.add(3, "M")
				.format("DD-MM-YYYY")
			this.model.status_id = 1
			this.model.status_effective_date = null
			this.model.shift_id = null
			this.model.pc_date_id = null
			this.model.payment_type_id = null
			this.model.is_tax_responsed_by_company = false
			this.model.is_spouse_dependant = false
			this.model.num_child_dependant = 0
			this.model.nssf_number = null
			this.model.ssn_no = null
			this.model.ssn_issued_date = null
			this.model.ssn_expiry_date = null
			this.model.ssn_issued_at = null
			this.model.nationality_id = 1
			this.model.date_of_birth = null
			this.model.emp_type_id = 1
			this.model.status_reason_id = null
			this.model.fp_serial_card = null
			this.model.num_of_payroll = 1
			this.model.branch_id = null
			this.model.province_code = null
			this.model.disctrict_code = null
			this.model.commune_code = null
			this.model.village_code = null
			this.model.house_no = null
			this.model.street_no = null
			this.model.is_employer = false
			this.model.is_audit = false
			this.model.is_sm = false
			this.model.details = []
			this.model.education_histories = []
			this.model.employment_histories = []
			this.model.attach_file_list = []
		},
		notice(not) {
			this.$Notice[not.type]({
				title: "EMPLOYEE",
				desc: not.text
			});
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.fetchResource();
		});
	},
	beforeRouteLeave(to, from, next) {
		AppOptions.appContentFullHeight = false;
		AppOptions.appContentClass = "";
		next();
	}
};
</script>
